import React from 'react';
import { getValuefromURL } from '../../helpers/analytics';
import { SignInPage } from './SignInPage';

export const WelcomePage: React.FC = () => {
  return (
    <SignInPage
      isWelcomePage={true}
      mas={getValuefromURL('s')}
      doNotAutoRedirect={getValuefromURL('stay') === 'here'}
    />
  );
};
